import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoComponent } from './components/page/demo/demo.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClientModule } from '@angular/common/http';
import { MatCardModule } from '@angular/material/card';
import { SafeHtmlPipe } from 'src/app/pipes/app/safe-html.pipe';
import { SafeUrlPipe } from 'src/app/pipes/app/safe-url.pipe';
import { CurrencyFormatterPipe } from 'src/app/pipes/app/currency-formatter.pipe';
import { DateFormatterPipe } from 'src/app/pipes/app/date-formatter.pipe';
import { EnumFormatterPipe } from 'src/app/pipes/app/enum-formatter.pipe';
import { PercentFormatterPipe } from 'src/app/pipes/app/percent-formatter.pipe';
import { YesNoFormatterPipe } from 'src/app/pipes/app/yes-no-formatter.pipe';
import { DateTimeFormatterPipe } from 'src/app/pipes/app/date-time-formatter.pipe';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';


@NgModule({
  declarations: [
    AppComponent,
    DemoComponent,

    //PIPES
    SafeHtmlPipe,
    SafeUrlPipe,
    CurrencyFormatterPipe,
    DateFormatterPipe,
    DateTimeFormatterPipe,
    EnumFormatterPipe,
    PercentFormatterPipe,
    YesNoFormatterPipe,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    DragDropModule,
    FormsModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
