import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DashboardModel } from 'src/app/models/domain/dashboard.model';
import { DashboardSearchModel } from 'src/app/models/domain/dashboard-search.model';

@Injectable({providedIn: 'root'})
export class DashboardService {
  constructor(private httpClient: HttpClient) {
  }

  search(searchModel: DashboardSearchModel | null = null): Observable<DashboardModel> {
    return this.httpClient.post<DashboardModel>(`${environment.apiUrl}/dashboard/search`, searchModel ??  {});
  };
}
