import { Component, OnInit } from '@angular/core';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem
} from '@angular/cdk/drag-drop';
import { DashboardService } from 'src/app/services/domain/dashboard.service';
import { DashboardModel } from 'src/app/models/domain/dashboard.model';
import { DashboardItemTypesEnum } from 'src/app/enums/domain/dashboard-item-types.enum';
import { FormControl, FormGroup } from '@angular/forms';
import { EnumModel } from 'src/app/models/app/enum.model';
import { ArrayUtilities } from 'src/app/utilities/app/array.utilities';
import { ObjectUtilities } from 'src/app/utilities/app/object.utilities';
import { DashboardItemModel } from 'src/app/models/domain/dashboard-item.model';
import { Observable, of, tap } from 'rxjs';

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss']
})
export class DemoComponent implements OnInit {
  allData: DashboardModel | null = null;
  data: DashboardModel | null = null;
  dataItemKeys: string[] = [];
  dashboardTypes = DashboardItemTypesEnum;
  defaultStartDateOffset = 3;

  technicianControl = new FormControl('');
  technicianOptions: EnumModel[] = [];

  serviceWriterControl = new FormControl('');
  serviceWriterOptions: EnumModel[] = [];

  globalSearchControl = new FormControl('');

  startDateFormControl = new FormControl();
  endDateFormControl = new FormControl();

  constructor(private dashboardService: DashboardService) {

  }

  ngOnInit(): void {
    let existingFilters = this.getLocalStorageFilters();

    console.log(existingFilters);

    this.setFilters(existingFilters);

    this.setData().subscribe(_ => {
      this.setLocalStorageFilters();
      this.onApplyFiltersClicked();
    });
  }

  onApplyFiltersClicked(): void {
    this.data = null;
    this.allData = null;

    this.setLocalStorageFilters();

    this.setData().subscribe(_ => {
      if (this.technicianControl.value?.length && !this.serviceWriterControl.value?.length) {
        this.onResetFiltersClicked();
        return;
      }

      let allData = JSON.parse(JSON.stringify(this.allData));

      if (allData) {
        for (let dataKey in allData.items) {
          let dashboardItems = allData.items[dataKey];

          allData.items[dataKey] = dashboardItems.filter((t: DashboardItemModel) => {
            // @ts-ignore
            if (this.technicianControl.value?.length && t.technician?.employeeId && this.technicianControl.value.indexOf(t.technician.employeeId) !== -1) {
              return true;
            }

            // @ts-ignore
            if (this.serviceWriterControl.value?.length && t.serviceWriter?.employeeId && this.serviceWriterControl.value.indexOf(t.serviceWriter.employeeId) !== -1) {
              return true;
            }

            if (this.globalSearchControl.value && this.globalSearchControl.value?.trim() !== '') {
              if (JSON.stringify(t).toLowerCase().indexOf(this.globalSearchControl.value?.toLowerCase()) !== -1) {
                return true;
              }
            } else if (!this.globalSearchControl.value || this.globalSearchControl.value?.trim() !== '') {
              return true;
            }

            return false;
          });
        }
      }

      //console.log(allData);

      this.data = allData;
      this.setLocalStorageFilters();
    });
  }

  onResetFiltersClicked(): void {
    this.data = this.allData;
    this.technicianControl.setValue(null);
    this.serviceWriterControl.setValue(null);
    this.globalSearchControl.setValue(null);
    this.clearLocalStorageFilters();
    this.setFilters();
    this.setLocalStorageFilters();
  }

  private setData(): Observable<DashboardModel | null> {
    let startISODate = this.getDateString(this.startDateFormControl.value);
    let endISODate = this.getDateString(this.endDateFormControl.value);

    if (startISODate !== null && endISODate !== null) {
      return this.dashboardService.search({startDate: startISODate, endDate: endISODate }).pipe(tap(data => {
        this.dataItemKeys = Object.keys(data.items);
        this.data = data;
        this.allData = data;

        for (let dataKey in this.data.items) {
          let dashboardItems = this.data.items[dataKey];

          for (let dashboardItem of dashboardItems) {
            if (dashboardItem?.technician?.employeeId && !this.technicianOptions.find(t => t.value === dashboardItem.technician.employeeId)) {
              this.technicianOptions.push({value: dashboardItem.technician.employeeId, label: dashboardItem.technician.fullName});
            }

            if (dashboardItem?.serviceWriter?.employeeId && !this.serviceWriterOptions.find(t => t.value === dashboardItem.serviceWriter.employeeId)) {
              this.serviceWriterOptions.push({value: dashboardItem.serviceWriter.employeeId, label: dashboardItem.serviceWriter.fullName});
            }
          }
        }

        ArrayUtilities.sortItemsByProperty(this.technicianOptions, ObjectUtilities.nameOf<EnumModel>('label'));
        ArrayUtilities.sortItemsByProperty(this.serviceWriterOptions, ObjectUtilities.nameOf<EnumModel>('label'));

      }, err => {
        console.error(err);
      }));
    }

    return of(null);
  }

  private getDateString(value: Date): string | null {
    if (value) {
      return value.toISOString().split('T')[0] + 'T00:00:00Z';
    }

    return null;
  }

  private getLocalStorageFilters(): any {
    let strValue = localStorage.getItem('dashboard_filters');

    if (strValue && strValue != '') {
      return JSON.parse(strValue);
    }

    return null;
  }

  private setLocalStorageFilters(): void {
    let filters = {
      startDate: this.startDateFormControl.value,
      endDate: this.endDateFormControl.value,
      serviceWriter: this.serviceWriterControl.value,
      technician: this.technicianControl.value
    }

    localStorage.setItem('dashboard_filters', JSON.stringify(filters));
  }

  private clearLocalStorageFilters(): void {
    localStorage.setItem('dashboard_filters', '');
  }

  private setFilters(existingFilters: any = null): void {
    let startDate = new Date();
    if (!existingFilters?.startDate) {
      startDate.setDate(startDate.getDate() - this.defaultStartDateOffset);
    } else {
      startDate = new Date(existingFilters.startDate);
    }

    this.startDateFormControl.setValue(startDate);

    let endDate = new Date();
    if (!existingFilters?.endDate) {
      endDate.setDate(endDate.getDate());
    } else {
      endDate = new Date(existingFilters.endDate);
    }

    this.endDateFormControl.setValue(endDate);

    if (existingFilters?.technician) {
      this.technicianControl.setValue(existingFilters.technician);
    }

    if (existingFilters?.serviceWriter) {
      this.serviceWriterControl.setValue(existingFilters.serviceWriter);
    }
  }

  drop(event: CdkDragDrop<number[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }
}
