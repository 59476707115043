import { ObjectUtilities } from 'src/app/utilities/app/object.utilities';

export class ArrayUtilities {
  static unique(value: any, index: number, self: any[]): boolean {
    return self.indexOf(value) === index;
  }

  static getUniqueValues(array: any[]): any[] {
    return array ? array.map(a => a).filter(ArrayUtilities.unique) : array;
  }

  static getUniqueItemsByProperty(array: any[], property: string): any[] {
    return array.filter((x, i, arr) => arr.findIndex(y => y && y !== null && typeof y !== 'undefined' && x !== null && typeof x !== 'undefined' && y[property] === x[property]) === i);
  }

  static getUniqueValuesOfProperty(array: any[], property: string): any[] {
    return array ? array.map(a => a[property]).filter(ArrayUtilities.unique) : array;
  }

  static getUniqueValuesOfSubProperty<T>(array: any[], subPropertyPath: string, propertyKey: string): any[] {
    return ArrayUtilities.getUniqueItemsByProperty(ArrayUtilities.flatten(array?.map(r => ObjectUtilities.getValueOfProperty<T[]>(r, subPropertyPath))), propertyKey);
  }

  static getAverage(numbers: number[]) {
    return ArrayUtilities.getSum(numbers) / numbers.length;
  }

  static getSum(numbers: number[]) {
    return numbers.reduce((a, b) => a + b, 0);
  }

  static moveInArray(arr: any[], fromIndex: number, toIndex: number): void {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  static removeItem(array: any[], item: any): any[] {
    return array.filter(a => a !== item);
  }

  static flatten<T>(arrays: T[][]): T[] {
    const flattened: T[] = [];
    arrays.forEach(array => {
      flattened.push(...array);
    });
    return flattened;
  }

  static areEqual<T>(a: T[], b: T[]) {
    if (a == null || b == null) {
      return false;
    }
    if (a.length !== b.length) {
      return false;
    }

    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) {
        return false;
      }
    }

    return true;
  }

  static areEqualByProperty<T>(a: T[], b: T[], propertyName: string) {
    if (a == null || b == null) {
      return false;
    }
    if (a.length !== b.length) {
      return false;
    }

    for (let i = 0; i < a.length; i++) {
      if (ObjectUtilities.getValueOfProperty(a[i], propertyName) !== ObjectUtilities.getValueOfProperty(a[i], propertyName)) {
        return false;
      }
    }

    return true;
  }

   static sortItemsByProperty(array: any[], property: string, descending: boolean = true): any[] {
    return array = array.sort((x, y) => {
      if (x[property] > y[property]) {
        return descending ? 1 : -1;
      }

      if (x[property] < y[property]) {
        return descending ? -1 : 1;
      }

      return 0;
    });
  }

  static sortItems(array: string[] | number[], descending: boolean = true): any[] {
    return array = array.sort((x, y) => {
      if (x > y) {
        return descending ? 1 : -1;
      }

      if (x < y) {
        return descending ? -1 : 1;
      }

      return 0;
    });
  }

  static toggleArrayByExistingValue(array: any, value: any) {
    const i = array.findIndex((x:any) => x === value);
    if (i !== -1) {
      array.splice(i, 1);
    } else {
      array.push(value);
    }
    return array;
  }

  static toggleArrayByExistingValueProperty(array: any, value: any, property: string) {
    const i = array.findIndex((x:any) => x === value[property]);
    if (i !== -1) {
      array.splice(i, 1);
    } else {
      array.push(value[property]);
    }
    return array;
  }

}
