<!--<div class="example-container">-->
<!--  <h2>Available numbers</h2>-->

<!--  <div-->
<!--    id="all"-->
<!--    cdkDropList-->
<!--    [cdkDropListData]="all"-->
<!--    cdkDropListConnectedTo="even"-->
<!--    class="example-list"-->
<!--    (cdkDropListDropped)="drop($event)"-->
<!--    [cdkDropListEnterPredicate]="noReturnPredicate">-->
<!--    @for (number of all; track number) {-->
<!--    <div-->
<!--        class="example-box"-->
<!--        [cdkDragData]="number"-->
<!--        cdkDrag>{{number}}</div>-->
<!--    }-->
<!--  </div>-->
<!--</div>-->

<!--<div class="example-container">-->
<!--  <h2>Even numbers</h2>-->

<!--  <div-->
<!--    id="even"-->
<!--    cdkDropList-->
<!--    [cdkDropListData]="even"-->
<!--    cdkDropListConnectedTo="all"-->
<!--    class="example-list"-->
<!--    (cdkDropListDropped)="drop($event)"-->
<!--    [cdkDropListEnterPredicate]="evenPredicate">-->
<!--    @for (number of even; track number) {-->
<!--    <div-->
<!--        class="example-box"-->
<!--        cdkDrag-->
<!--        [cdkDragData]="number">{{number}}</div>-->
<!--    }-->
<!--  </div>-->
<!--</div>-->

<!--<div class="filter-container">-->
<!--  <mat-form-field>-->
<!--    <mat-label>Teh</mat-label>-->
<!--    <mat-select [(value)]="selectedTechnicianIds" multiple>-->
<!--      @for (topping of toppingList; track topping) {-->
<!--      <mat-option [value]="topping">{{topping}}</mat-option>-->
<!--      }-->
<!--    </mat-select>-->
<!--  </mat-form-field>-->
<!--</div>-->
<div class="main-container">
  <div class="filter-container">
    <div class="filter-container-left">
      <div class="filter-control-container">
        <mat-form-field class="example-full-width">
          <mat-label>Search</mat-label>
          <input matInput [formControl]="globalSearchControl" (keydown.enter)="onApplyFiltersClicked()">
        </mat-form-field>
      </div>
      <div class="filter-control-container">
        <mat-form-field *ngIf="technicianOptions?.length">
          <mat-label>Technician</mat-label>
          <mat-select [formControl]="technicianControl" multiple>
            @for (technician of technicianOptions; track technician) {
            <mat-option [value]="technician.value">{{technician.label}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="filter-control-container">
        <mat-form-field *ngIf="serviceWriterOptions?.length">
          <mat-label>Service Writer</mat-label>
          <mat-select [formControl]="serviceWriterControl" multiple>
            @for (serviceWriter of serviceWriterOptions; track serviceWriter) {
            <mat-option [value]="serviceWriter.value">{{serviceWriter.label}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="filter-control-container" >
        <mat-form-field>
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate [formControl]="startDateFormControl" placeholder="Start date">
            <input matEndDate [formControl]="endDateFormControl" placeholder="End date">
          </mat-date-range-input>
          <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div class="filter-control-container">
        <button mat-flat-button (click)="onApplyFiltersClicked()" color="primary" style="margin-left:10px;margin-top:2px;width:100px;height:50px;">Apply</button>
        <button mat-flat-button (click)="onResetFiltersClicked()" color="primary" style="margin-left:10px;margin-top:2px;width:100px;height:50px;">Reset</button>
      </div>
    </div>
  </div>
  <div class="status-scroll-container">
    <div class="status-containers" *ngIf="data">
      <div class="status-container" *ngFor="let dataItemKey of dataItemKeys">
        <div class="status-title">{{dataItemKey}}</div>
        <div class="status-items-scroll-container">
          <div class="status-item" *ngFor="let statusCard of data.items[dataItemKey]">
            <mat-card>
              <mat-card-header>
                <ng-container *ngIf="statusCard.type === dashboardTypes.Appointments">
                  {{statusCard.appointment.startDateTime | dateTimeFormatter}}
                </ng-container>
                <ng-container *ngIf="statusCard.type === dashboardTypes.Status">
                  {{statusCard.customer.lastName}}, {{statusCard.customer.firstName}}
                </ng-container>
              </mat-card-header>
              <mat-card-content>

                <ng-container *ngIf="statusCard.type === dashboardTypes.Appointments">
                  <div *ngIf="statusCard.appointment.customer">
                    CUSTOMER: {{statusCard.appointment.customer.lastName}}, {{statusCard.appointment.customer.firstName}}
                  </div>
                  <div *ngIf="statusCard.appointment.vehicle">
                    VEHICLE: {{statusCard.appointment.vehicle.year}} {{statusCard.appointment.vehicle.make}} {{statusCard.appointment.vehicle.model}}
                  </div>
                  <div class="customer-concern-container">
                    DESCRIPTION: {{statusCard.appointment.description || 'N/A'}}
                  </div>
                </ng-container>

                <ng-container *ngIf="statusCard.type === dashboardTypes.Status">
                  <div>
                    VEHICLE: {{statusCard.vehicle.year}} {{statusCard.vehicle.make}} {{statusCard.vehicle.model}}
                  </div>
                  <div>
                  <span *ngIf="statusCard.serviceWriter">
                    SERVICE WRITER: {{statusCard.serviceWriter.lastName}}, {{statusCard.serviceWriter.firstName}}
                  </span>
                    <span *ngIf="!statusCard.serviceWriter">
                    SERVICE WRITER: N/A
                  </span>
                  </div>
                  <div>
                  <span *ngIf="statusCard.technician">
                    TECHNICIAN: {{statusCard.technician.lastName}}, {{statusCard.technician.firstName}}
                  </span>
                    <span *ngIf="!statusCard.technician">
                    TECHNICIAN: N/A
                  </span>
                  </div>
                  <div class="customer-concern-container" *ngIf="statusCard.repairOrder?.customerConcerns?.length">
                    CONCERN: {{statusCard.repairOrder.customerConcerns.join(',')}}
                  </div>
                </ng-container>

              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--<pre *ngIf="data">{{data | json}}</pre>-->



